import axios from 'axios';

const api = axios.create({
   baseURL: 'https://back.hml.mvp.m87.me',
  // baseURL: 'http://localhost:3334',

  // URL to test in mobile
  // baseURL: 'http://10.0.0.103:3334',
});

export default api;
