import EventDTO from '@/dtos/events/EventDTO';
import GroupedEventsDTO from '@/dtos/events/GroupedEventsDTO';

const groupEvents = (events: EventDTO[]): GroupedEventsDTO[] => {
  const firstEvent = events[0];
  if (!firstEvent) return [];

  const grouped: GroupedEventsDTO[] = [];
  let current = { name: firstEvent.name, events: [firstEvent] };
  events.forEach((event, index) => {
    if (event.name === current.name) {
      current.events.push(event);
    }
    if (event.name !== current.name || index === events.length - 1) {
      grouped.push(current);

      current = { name: event.name, events: [event] };
    }
  });

  return grouped;
};

export default groupEvents;
